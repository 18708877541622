// @import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");

// @font-face {
//   // font-family: "Lato";
//   // font-display: swap;
//   // font-style: normal;
//   // src: url("./fonts/Lato-Bold.ttf") format("truetype"); /* IE9 Compat Modes */
//   // src: url("./fonts/Lato-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
// }

$h1-size: 14px;
$h1-font-weight: 700;

$h2-size: 14px;
$h2-font-weight: 700;

$normal-size: 14px;
$normal-font-weight: 400;

$small-size: 12px;
$small-font-weight: 400;

$super-bar-width: 250px;

$small-bold-size: 12px;
$small-bold-font-weight: 700;

$small-h1-size: 14px;
$small-h1-font-weight: 700;

$leftMargin: 40px;

$topBarHeight: 65px;

$tooltip-size: 12px;
$tooltip-font-weight: 400;
$NavBarSize: 110px;
$IconsSize: 40px;
$NavBarPrimaryColor: #0d3768;
$NavBarSecondaryColor: #7ed321;
$HoverColor: rgba(255, 255, 255, 0.1);
$timeLineColor: #0d3768;
$HoverSecondary: #eef2f5;
$LineColor: rgba(0, 0, 0, 0.13);
$MainLogoHeight: 60px;

$ScrollColor: #bebbbb;

$ButtonColor: #73b71e;

$PendingColor: #3a86ff;
// #8e8e8e;

$AssignedColor: #ffbe0b;

$AssignedColor2: #17cec5;

$ProblemReport: #e7495d;

$DelayedReport: #9353c9;

$InProcess: #f78d37;

$CompletedColor: #73b71e;

$ResponseColor: #9e9e9ee3;

// $PendingColor: #f1ea98;

// $AssignedColor: #7cc0f9;

// $InProcess: #1b87e3;

// $CompletedColor: #6fc66f;

// $ProblemReport: #ca2828;

// $DelayedReport: #e3931b;

// $AssignedColor2: #17cec5;

$darkBlue: #00376c;

$selected: #79a5dd;

//***********************************
$BaseSecondaryColorDarker: #141c21;
$BaseColor: #ffffff;
$BaseSecondaryColor: #1c262d;
$PrimaryColor: #428ed8;
$controlsMapColor: #d0dde7;
//***********************************

$font-400-weight: 400;
$font-700-weight: 700;

$FontFamily: "Lato", sans-serif;
// #79A5DD

h5,
h4,
h3,
h2,
h1,
p {
  font-family: "Lato", sans-serif;
}

@mixin h1-style {
  font-size: $h1-size;
  font-weight: $h1-font-weight;
  font-family: "Lato", sans-serif;
  color: black;
}

@mixin h2-style {
  font-size: $h2-size;
  font-weight: $h2-font-weight;
  font-family: "Lato", sans-serif;
}

@mixin normal-style {
  font-size: $normal-size;
  font-weight: $normal-font-weight;
  font-family: "Lato", sans-serif;
}

@mixin small-style {
  font-size: $small-size;
  font-weight: $small-font-weight;
  font-family: "Lato", sans-serif;
}

@mixin small-bold-style {
  font-size: $small-bold-size;
  font-weight: $small-bold-font-weight;
  font-family: "Lato", sans-serif;
}

@mixin small-h1-style {
  font-size: $small-h1-size;
  font-weight: $small-h1-font-weight;
  font-family: "Lato", sans-serif;
}

@mixin tooltip-style {
  font-size: $tooltip-size;
  font-weight: $tooltip-font-weight;
  font-family: "Lato", sans-serif;
}

@mixin scroll {
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-4 {
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}
@mixin scroll-4-height {
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 4px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-4-height {
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 4px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-auto-2 {
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 2px;
    height: 0px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-auto-none {
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 2px;
    height: 0px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-none {
  overflow: auto;
  //scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

.title-data {
  @include h1-style;
  font-size: 16px !important;
}
.subtitle-data {
  @include normal-style;
}
