@import "../../general-styles/variables.scss";
$h1-size-login: 28px;
$bottomInputColor: #4a90e2;

.login-background {
  width: 100%;
  height: 100%;
  min-height: 670px;
  position: absolute;
  background-image: url(../../assets/img/avenue.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.login {
  height: 100%;
  min-height: 670px;
  width: 100%;
  position: relative;
  background: rgba(7, 58, 107, 0.9);
  display: flex;
  flex-direction: row;
  justify-content: center;
  .lng-selector {
    position: absolute;
    right: 10vw;
    top: 50px;
    height: fit-content;
    width: fit-content;
    .flags-box {
      right: -10px;
      top: 40px;
    }
  }
  .btn-box {
    align-items: center !important;
    .btn-done {
      text-transform: uppercase;
    }
  }
}
.forgot-password {
  .data-box {
    height: 340px !important;
  }
  .btn-box {
    align-items: space-around !important;
    justify-content: space-between !important;
    .btn-done {
      padding: 15px 50px !important;
    }
  }
}

.password-confirmation {
  .data-box {
    height: 400px !important;
  }
  .btn-box {
    align-items: space-around !important;
    justify-content: flex-end !important;
    .btn-done {
      padding: 15px 50px !important;
    }
  }
}
.login-box {
  position: relative;
  height: 100%;
  min-height: 670px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login-logo {
    @include h1-style;
    font-size: $h1-size-login;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    color: white;
  }
  img {
    height: 76px;
    width: 100px;
    margin-bottom: 10px;
  }
  .data-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    height: 460px;
    background: white;
    width: 100%;
    border-radius: 10px;
    .data-box-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .title {
        @include h1-style;
        font-size: $h1-size-login;
        margin-bottom: 10px;
      }
      .sub-title {
        @include small-style;
        color: #737373;
        text-align: center;
        padding: 0 60px;
      }
    }
    .inputs-box {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 10px 40px;
      @include small-h1-style;
      .email-box {
        padding: 10px 0;
        input:focus {
          border-bottom: 1.3px solid $bottomInputColor;
          transition: border-bottom 0.5s;
        }
      }
      .password-box {
        padding: 10px 0;
        input:focus {
          border-bottom: 1.3px solid $bottomInputColor;
          transition: border-bottom 0.5s;
        }
      }
      input {
        width: 100%;
        border: 0;
        border-bottom: 1.3px solid #d4d4d4;
        padding: 10px 0;
        outline: none;
        @include small-bold-style;
        font-size: 15px;
      }
    }
    .checkbox-error {
      .custom-control-label::before {
        border: red solid 1px !important;
      }
    }
    .agreements-box {
      @include small-style;
      color: #737373;
      font-size: 16px;
      width: 100%;
      padding: 10px 40px;
      padding-bottom: 2px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      a {
        text-decoration: none;
      }
      div:hover {
        cursor: pointer;
        color: #666666;
      }
    }
    .forgot-box {
      @include small-style;
      color: #737373;
      font-size: 16px;
      width: 100%;
      padding: 10px 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      div:hover {
        cursor: pointer;
        color: #666666;
      }
    }
    .btn-box {
      width: 100%;
      padding: 10px 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      .btn-done {
        @include normal-style;
        border: 0;
        border-radius: 5px;
        background: $ButtonColor;
        color: white;
        text-transform: uppercase;
        padding: 15px 25px;
      }
    }
  }
  .password-eye {
    position: absolute;
    right: 50px;
    // top: 550px;
    margin-top: 15px;
    &:hover {
      cursor: pointer;
      // g {
      //   fill: $bottomInputColor;
      // }
    }
  }

  .password-eye-closed {
    position: absolute;
    right: 50px;
    // top: 550px;
    margin-top: 12px;
    &:hover {
      cursor: pointer;
      // g {
      //   fill: $bottomInputColor;
      // }
    }
  }

  .eye-active {
    g {
      fill: $bottomInputColor;
    }
    g:hover {
      fill: #bebebe;
    }
  }

  .eye-deactive {
    g {
      fill: #bebebe;
    }
    // g:hover {
    //   fill: $bottomInputColor;
    // }
  }

  .copyright {
    color: white;
    @include small-style;
    margin-top: 25px;
  }
}

.ant-form-explain {
  color: #ff0046 !important;
}

.click-forgot {
  @include normal-style;
  &:hover {
    cursor: pointer;
    color: #666666;
  }
  color: #737373;
  font-weight: 700 !important;
}
